





































import GpCheckbox from '@/components/custom/CheckboxView.vue';
import GpCheckboxList from '@/components/custom/CheckboxListView.vue';
import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';

@Component(
    {name: 'gp-table', components: {GpCheckboxList, GpCheckbox }}
)
export default class TableView extends Vue {
  @Model('change', {type: Array}) selected!: any[] | [];
  @Prop({type: String}) search!: string | '';
  @Prop({type: Array}) searchFields?: [];
  @Prop({type: Object}) header?: any | {};
  @Prop({type: Boolean}) checkbox?: boolean;
  @Prop({type: Boolean, default: true}) action?: boolean;
  @Prop({type: Array}) items?: [];

  checkboxStatus: string = 'empty';
  get filterItems() {
    if (this.search == '' || this.searchFields?.length == 0) {
      return this.items?.sort(this.sortItem) || [];
    }
    return this.items?.filter(item => this.matchItem(item))?.sort(this.sortItem) || [];
  }

  matchItem(item) {
    return this.searchFields?.some((field) => {
      if (typeof (item[field]) == 'undefined' || !item.hasOwnProperty(field)) {
        return false
      }
      return item[field].toUpperCase().includes(this.search.toUpperCase())
    }, {item: item, search: this.search})
  }

  sortItem(a, b) {
    const field = this.header.list[this.header.sort.index].value;
    let fieldA = '';
    let fieldB = '';
    if (!(typeof (a[field]) == 'undefined') && a.hasOwnProperty(field)) {
      fieldA = a[field].toUpperCase();
    }
    if (!(typeof (b[field]) == 'undefined') && b.hasOwnProperty(field)) {
      fieldB = b[field].toUpperCase();
    }
    if (this.header.sort.order == 'asc') {
      return fieldA > fieldB ? 1 : -1
    } else {
      return fieldA < fieldB ? 1 : -1
    }
  }

  headerSort(row, i) {
    if (!row.sortable) {
      return '';
    }
    return this.header.sort.index == i ? 'sort ' + this.header.sort.order : 'sort';
  }

  toggleSort(row, i) {
    if (row.sortable) {
      if (this.header.sort.index == i) {
        this.$emit('sort', i, this.header.sort.order == 'asc' ? 'desc' : 'asc');
      } else {
        this.$emit('sort', i, this.header.sort.order);
      }
    }
  }

  isSuperAdmin(item) {
    return item?.roles?.superAdmin
  }

  onCheckList() {
    this.$emit('check-list');
  }

  @Watch('selected')
  onSelected(val: []) {
    if (val.length == this.items?.length && val.length > 0)
      this.checkboxStatus = 'full'
    else if (val.length == 0)
      this.checkboxStatus = 'empty'
    else
      this.checkboxStatus = 'some'
  }
}
